import update from 'immutability-helper';
import {
    find,
    head,
    min,
    max,
    maxBy,
    isNil,
    map,
    round,
    isObject,
    identity,
} from 'lodash';
import moment from 'moment';
import { latLngPrecision, unknownValue } from './constants';
import { setSelectedObservations } from '../actions/filterSet';

import { pluckFieldDefinitionsByName } from './schemaHelper';
import { getObservationTableFieldsFromProjectDataSchema } from './queryHelper';
import { userIsProjectManager } from '../util/projectHelper';
import { formatUnit } from '../util/units';

export const stationNameVirtualField = Object.freeze({
    label: 'Stations',
    name: 'stationName',
    type: 'String',
});

export const syntheticFieldsForAllProjects = [
    Object.freeze({
        allowOther: false,
        description:
            'The latitude of the location where the observation was collected',
        entryType: 'Default',
        isDefault: true,
        label: 'Latitude',
        maximum: null,
        minimum: null,
        name: 'Latitude',
        precision: latLngPrecision,
        required: true,
        type: 'Number',
        units: '°',
        unitsAvailable: null,
    }),
    Object.freeze({
        allowOther: false,
        description:
            'The longitude of the location where the observation was collected',
        entryType: 'Default',
        isDefault: true,
        label: 'Longitude',
        maximum: null,
        minimum: null,
        name: 'Longitude',
        precision: latLngPrecision,
        required: true,
        type: 'Number',
        units: '°',
        unitsAvailable: null,
    }),
];

export const createSyntheticFields = fieldDefinition => {
    const fields = [];
    const baseNumberField = Object.freeze({
        allowOther: false,
        entryType: 'Default',
        isDefault: true,
        maximum: null,
        minimum: null,
        precision: 0,
        required: true,
        type: 'Number',
        units: null,
        unitsAvailable: null,
        derivedFrom: fieldDefinition,
    });
    if (isNil(fieldDefinition)) {
        return fields;
    }
    if (!['Date', 'Time', 'DateTime'].includes(fieldDefinition.type)) {
        return fields;
    }

    if (
        fieldDefinition.type === 'Date' ||
        fieldDefinition.type === 'DateTime'
    ) {
        // The name properties use a conditional to preserve `*OfObservation`
        // field names from when the synthetic date and time field handling
        // was only used on the Observation Date (collectionDate) field
        fields.push(
            Object.assign({}, baseNumberField, {
                name:
                    fieldDefinition.name === 'collectionDate'
                        ? 'DayOfObservation'
                        : `${fieldDefinition.name}__day`,
                label: `${fieldDefinition.label} (Day)`,
                description: `${fieldDefinition.description} (Day)`,
            }),
            Object.assign({}, baseNumberField, {
                minimum: 0,
                name:
                    fieldDefinition.name === 'collectionDate'
                        ? 'YearOfObservation'
                        : `${fieldDefinition.name}__year`,
                label: `${fieldDefinition.label} (Year)`,
                description: `${fieldDefinition.description} (Year)`,
            }),
            Object.assign({}, baseNumberField, {
                minimum: 0,
                name:
                    fieldDefinition.name === 'collectionDate'
                        ? 'YearDayOfObservation'
                        : `${fieldDefinition.name}__yearday`,
                label: `${fieldDefinition.label} (Year/Day)`,
                description: `${fieldDefinition.description} (Year/Day)`,
            }),
            {
                allowOther: false,
                description: `${fieldDefinition.description} (Month)`,
                derivedFrom: fieldDefinition,
                entryType: 'Default',
                isDefault: true,
                label: `${fieldDefinition.label} (Month)`,
                maximum: null,
                minimum: null,
                name:
                    fieldDefinition.name === 'collectionDate'
                        ? 'MonthOfObservation'
                        : `${fieldDefinition.name}__month`,
                precision: 0,
                required: true,
                type: 'String',
                units: null,
                unitsAvailable: null,
                values: [
                    { value: 0, label: 'January' },
                    { value: 1, label: 'February' },
                    { value: 2, label: 'March' },
                    { value: 3, label: 'April' },
                    { value: 4, label: 'May' },
                    { value: 5, label: 'June' },
                    { value: 6, label: 'July' },
                    { value: 7, label: 'August' },
                    { value: 8, label: 'September' },
                    { value: 9, label: 'October' },
                    { value: 10, label: 'November' },
                    { value: 11, label: 'December' },
                ],
            }
        );
    }
    if (fieldDefinition.name === 'collectionDate') {
        fields.push(
            Object.assign({}, baseNumberField, {
                name: 'TimeOfObservation',
                label: `${fieldDefinition.label} (Time)`,
                description: `${fieldDefinition.description} (Time)`,
            })
        );
    }
    if (fieldDefinition.type === 'DateTime') {
        fields.push(
            Object.assign({}, baseNumberField, {
                name: `${fieldDefinition.name}__time`,
                label: `${fieldDefinition.label} (Time)`,
                description: `${fieldDefinition.description} (Time)`,
            })
        );
    }
    if (fieldDefinition.type === 'Time') {
        fields.push(
            Object.assign({}, baseNumberField, {
                name: `${fieldDefinition.name}__time`,
                label: `${fieldDefinition.label}`,
                description: `${fieldDefinition.description}`,
            })
        );
    }
    return fields;
};

export const visFilterSetName = (visId, filterSetId) =>
    `VIS-${visId ? visId : 'WORKING'}-${filterSetId}`;

export function extractFilterSets(data, filterSets) {
    const config = data?.configuration;

    if (!config?.filterSets) {
        return [];
    }

    return config.filterSets.map(f => {
        const widgets =
            config.widgets?.filter(w => w.filterSets.indexOf(f.id) >= 0) || [];
        const filterSetName = visFilterSetName(data.id, f.id);

        const loading = filterSets?.[filterSetName]?.fetching;

        // Filter sets originally has a single `fields` property doing double
        // duty as the list of fields to fetch and the ordered list of fields to
        // display as table columns. To separate those concerns we added
        // `displayFields`
        const displayFields = isNil(f.displayFields)
            ? isNil(f.fields)
                ? undefined
                : [...f.fields]
            : [...f.displayFields];
        return Object.assign(f, { widgets, loading, displayFields });
    });
}

export function nextFilterSetId(config) {
    // Use the top-level `filterSetIdSequence` but fall back to looking a the
    // existing filter set items if it does not exist
    const seqId = config.filterSetIdSequence || 0;
    const filterSetWithMaxId = maxBy(config.filterSets, fs => fs.id);
    const maxId = Math.max(filterSetWithMaxId?.id || 0, seqId);
    return maxId + 1;
}

export function appendFilterSet(project, schema, config, options) {
    const dataSource = find(
        project?.dataSources,
        d => d.schema === schema?.name
    );

    return appendFilterSetWithDataSource(dataSource, config, options);
}

export function appendFilterSetWithDataSource(dataSource, config, options) {
    // We keep track of the next ID sequence at the top level to avoid reusing
    // the same ID when the filter set with the max ID is deleted and another is
    // added
    const nextId = nextFilterSetId(config);

    return update(config, {
        filterSetIdSequence: { $set: nextId },
        filterSets: {
            $push: [
                Object.assign(
                    {},
                    {
                        id: nextId,
                        dataSource: dataSource?.id,
                        label: 'New filter set',
                    },
                    options
                ),
            ],
        },
    });
}

// Given the current filterSet `state`, a `from` visId, and a `to` visId,
// returns the new state with the contents of `from` copied into `to`.
// If `config.deleteSource` is true, then the source filterSets will be removed.
export function copyFilterSets(state, from, to, config) {
    const defaultOptions = { deleteSource: false };
    const options = { ...defaultOptions, ...config };
    return update(state, {
        filterSets: obj => {
            const ret = Object.assign({}, obj);
            for (const key in obj) {
                if (key.startsWith(`VIS-${from}-`)) {
                    ret[key.replace(`VIS-${from}-`, `VIS-${to}-`)] = ret[key];
                    if (options.deleteSource) {
                        delete ret[key];
                    }
                }
            }
            return ret;
        },
    });
}

export function nextWidgetId(config) {
    // Use the top-level `widgetIdSequence` but fall back to looking a the
    // existing widgets if it does not exist
    const seqId = config.widgetIdSequence || 0;
    const widgetWithMaxId = maxBy(config.widgets, fs => fs.id);
    const maxId = Math.max(widgetWithMaxId?.id || 0, seqId);
    return maxId + 1;
}

export function appendWidget(config, options) {
    // We keep track of the next ID sequence at the top level to avoid reusing
    // the same ID when the filter set with the max ID is deleted and another is
    // added
    const nextId = nextWidgetId(config);

    return update(config, {
        widgetIdSequence: { $set: nextId },
        widgets: {
            $push: [
                Object.assign(
                    {},
                    {
                        id: nextId,
                        label: 'New data display',
                    },
                    options
                ),
            ],
        },
    });
}

export function filterSetNameForVisualizationWidget(data, widgetId) {
    const { id: visId, configuration: config } = data;
    const widget = find(config?.widgets, { id: widgetId });
    // TODO: If multiple filter set widgets are added, support returning
    // multiple names;
    const filterSetId = head(widget?.filterSets);
    return filterSetId ? visFilterSetName(visId, filterSetId) : undefined;
}

export function extractWidgets(data, filterSets, projectData, schemas, user) {
    const config = data?.configuration;

    if (!config?.filterSets) {
        return [];
    }

    const isManager = userIsProjectManager(user, projectData);
    const useStations = projectData?.useStations;

    return config.widgets.map(w => {
        // TODO: Support multiple filter sets on one visualization
        const filterSetName = filterSetNameForVisualizationWidget(data, w.id);
        const filterSet = filterSets[filterSetName];
        const visFilterSet = find(config.filterSets, { id: w.filterSets[0] });
        const dataSource = find(projectData?.dataSources, {
            id: visFilterSet.dataSource,
        });
        const schema = schemas?.[dataSource?.schema];

        const allFieldNames = !isNil(schema?.data)
            ? getObservationTableFieldsFromProjectDataSchema(
                  schema.data,
                  isManager,
                  useStations
              )
            : [];

        const schemaFieldDefinitions = !isNil(schema?.data)
            ? pluckFieldDefinitionsByName(schema.data, allFieldNames)
            : [];

        const fieldDefinitions = schema?.data && [
            ...schemaFieldDefinitions,
            ...syntheticFieldsForAllProjects,
            ...schemaFieldDefinitions.flatMap(createSyntheticFields),
            ...(schema?.data.useStations ? [stationNameVirtualField] : []),
        ];

        return Object.assign({}, w, {
            filterSet,
            filterSetName,
            schema,
            fieldDefinitions,
            filterSetLabel: visFilterSet.label,
        });
    });
}

export function conditionallyDispatchVisualizationFilterSetFetches(
    projectData,
    visualizationData,
    filterSets,
    dispatch,
    action,
    user,
    schemas
) {
    extractFilterSets(visualizationData, filterSets).forEach(fs => {
        const filterSetName = visFilterSetName(visualizationData.id, fs.id);
        const dataSource = find(projectData?.dataSources, {
            id: fs.dataSource,
        });
        const schemaName = dataSource?.schema;
        const schema = schemas?.[dataSource?.schema];
        const url = dataSource?.url;

        const needToFetch =
            // We check for `projectData` so that we don't try and fetch data
            // before the project is loaded.
            projectData &&
            // We need the schema to be loaded to access the full list of schema fields
            schema?.data &&
            // Only fetch filterSets that are used in some widget
            fs.widgets?.length &&
            !filterSets[filterSetName]?.fetching &&
            !filterSets[filterSetName]?.results &&
            !filterSets[filterSetName]?.error;

        if (needToFetch) {
            // We always fetch all available fields to ensure all data is available
            // to visualization data displays
            fs.fields = getObservationTableFieldsFromProjectDataSchema(
                schema.data,
                userIsProjectManager(user, projectData),
                projectData?.useStations
            );

            dispatch(
                action(
                    {
                        filterSetName,
                        filters: fs,
                        schemaName,
                    },
                    url
                )
            );
        }
    });
}

export const getDayOfYearFromMoment = date => {
    // Use a leap year when calculating day of year so that when comparing
    // across years day of year is always aligned (eg April 2 == 93)
    return moment(date)
        .year(2024)
        .dayOfYear();
};

export const getMinutesFromMoment = date => {
    const maybeExtraMinute = date.seconds() >= 30;
    return date.hours() * 60 + date.minutes() + maybeExtraMinute;
};

const getYearDay = (year, dayOfYear) => year * 1000 + dayOfYear;
export const getYearDayFromMoment = m =>
    getYearDay(m.year(), getDayOfYearFromMoment(m));

export const extractDateAndTimeDerivativeValues = (field, o) => {
    if (isNil(field?.type)) {
        throw new Error('field argument did not have a type property');
    }
    if (isNil(field?.name)) {
        throw new Error('field argument did not have a name property');
    }
    if (isNil(o?.attributes)) {
        throw new Error('o argument did not have an attributes property');
    }
    let values = {};
    if (isNil(o.attributes[field.name]) && isNil(o[field.name])) {
        return values;
    }
    const rawValue = o.attributes[field.name] || o[field.name];
    if (field.type === 'Date' || field.type === 'DateTime') {
        // Dates represented as objects use 1-based month numbering but
        // visualizations expect 0-based month numbers
        const dateValue = isObject(rawValue)
            ? moment({
                  month: rawValue.month - 1,
                  day: rawValue.day,
                  // Data should be saved with a 4-digit year but handle 2-digit
                  // years by assuming they are in the 2000s
                  year:
                      rawValue.year < 100
                          ? 2000 + rawValue.year
                          : rawValue.year,
              })
            : moment(rawValue).utc();
        const dayOfYear = getDayOfYearFromMoment(dateValue);
        values = Object.assign(values, {
            [`${field.name}__month`]: dateValue.month(),
            [`${field.name}__day`]: dayOfYear,
            [`${field.name}__year`]: dateValue.year(),
            [`${field.name}__yearday`]: getYearDay(dateValue.year(), dayOfYear),
            [field.name]: dateValue.format('YYYY-MM-DD'),
        });
        // collectionDate sometimes has Time set even if it is not officially
        // DateTime field. Keep this functionality in for backwards compat
        if (field.type === 'DateTime' || field.name === 'collectionDate') {
            values = Object.assign(values, {
                [`${field.name}__time`]: getMinutesFromMoment(dateValue),
            });
        }
        if (field.name === 'collectionDate') {
            values = Object.assign(values, {
                MonthOfObservation: values.collectionDate__month,
                DayOfObservation: values.collectionDate__day,
                TimeOfObservation: values.collectionDate__time,
                YearOfObservation: values.collectionDate__year,
                YearDayOfObservation: values.collectionDate__yearday,
            });
        }
    }
    if (field.type === 'Time') {
        if (!isNil(rawValue)) {
            const maybeExtraMinute = rawValue.second >= 30 ? 1 : 0;
            const minutes =
                rawValue.hour * 60 + rawValue.minute + maybeExtraMinute;
            values = Object.assign(values, {
                [`${field.name}__time`]: minutes,
            });
        }
    }
    return values;
};

// Create an array of objects where each object represents an observation and
// includes field values from the observation and station along with "synthetic"
// fields generated from the collection date and location.
export const extractObservationValues = (
    fieldDefinitions,
    filterSetResults
) => {
    const dateAndTimeFieldDefinitions = fieldDefinitions.filter(fd =>
        ['Date', 'Time', 'DateTime'].includes(fd.type)
    );
    return filterSetResults?.flatMap(s =>
        s.observations.flatMap(o =>
            Object.assign(
                {},
                // Raw attributes first so that they get overridden by the outputs of derivative
                // value creation
                o.attributes,
                s.attributes,
                ...(dateAndTimeFieldDefinitions.map(fd =>
                    extractDateAndTimeDerivativeValues(fd, o)
                ) || []),
                { Latitude: s.geometry.y },
                { Longitude: s.geometry.x },
                { stationId: s.stationId, observationId: o.observationId },
                { stationName: s.stationName }
            )
        )
    );
};

// Based on http://bl.ocks.org/benvandyke/8459843
// Returns slope, intercept and r-square of the best fit line calculated from
// the parallel arrays of X and Y coordinates.
export function leastSquares(xSeries, ySeries) {
    var reduceSumFunc = function(prev, cur) {
        return prev + cur;
    };

    var xBar = (xSeries.reduce(reduceSumFunc) * 1.0) / xSeries.length;
    var yBar = (ySeries.reduce(reduceSumFunc) * 1.0) / ySeries.length;

    var ssXX = xSeries
        .map(function(d) {
            return Math.pow(d - xBar, 2);
        })
        .reduce(reduceSumFunc);

    var ssYY = ySeries
        .map(function(d) {
            return Math.pow(d - yBar, 2);
        })
        .reduce(reduceSumFunc);

    var ssXY = xSeries
        .map(function(d, i) {
            return (d - xBar) * (ySeries[i] - yBar);
        })
        .reduce(reduceSumFunc);

    var slope = ssXY / ssXX;
    var intercept = yBar - xBar * slope;
    var rSquare = Math.pow(ssXY, 2) / (ssXX * ssYY);

    return { slope, intercept, rSquare };
}

// Use a leap year so that there are always 366 days in the year
export const dayOfYear = v => moment('2024-02-29').dayOfYear(Math.floor(v));

export const dayOfYearString = v => dayOfYear(v).format('MMM D');

export const yearDay = v => {
    if (isNil(v)) {
        return v;
    }
    const year = parseInt(v.toString().substr(0, 4), 10);
    const day = parseInt(v.toString().substr(4, 3), 10);
    return dayOfYear(day).year(year);
};

export const yearDayString = v => yearDay(v)?.format('MMM D YYYY');

export const timeOfDay = v =>
    moment()
        .hours(v / 60)
        .minutes(v % 60);

export const timeOfDayString = v => timeOfDay(v).format('h:mm a');

export const getVisualizationLink = ({ id }) =>
    `${window.location.origin}/visualizations/${id}`;

export const getVisualizationEmbedLink = ({ id }) =>
    `${getVisualizationLink({ id })}/?embed`;

export const visualizationBarClickHandler = props => {
    const { e, selectedObservations, item, dispatch } = props;

    const selectedBarNames = selectedObservations?.selectedBarNames;

    const selectedObservationIds = new Set(
        selectedObservations[item.filterSetName]
    );

    // If the range plot doesn't already control the map selections
    // set the observations for the clicked bar. This is important
    // to check because names of bars could be the same across
    // visualizations
    if (selectedObservations.controllingWidget !== item.id) {
        dispatch(
            setSelectedObservations({
                controllingWidget: item.id,
                filterSetName: item.filterSetName,
                selectedBarNames: [e.name],
                observationIds: Array.from(e.observationIds),
            })
        );
    } else {
        // If the range plot already controls the map selections
        if (!selectedBarNames) {
            dispatch(
                setSelectedObservations({
                    controllingWidget: item.id,
                    filterSetName: item.filterSetName,
                    selectedBarNames: [e.name],
                    observationIds: [
                        ...Array.from(selectedObservationIds),
                        ...Array.from(e.observationIds),
                    ],
                })
            );
        } else if (!selectedBarNames.includes(e.name)) {
            // Highlight the clicked bar if it's a different
            // than the one that is already selected
            dispatch(
                setSelectedObservations({
                    controllingWidget: item.id,
                    filterSetName: item.filterSetName,
                    selectedBarNames: [...selectedBarNames, e.name],
                    observationIds: [
                        ...Array.from(selectedObservationIds),
                        ...Array.from(e.observationIds),
                    ],
                })
            );
        } else {
            // Otherwise toggle the highlighted bar off
            dispatch(
                setSelectedObservations({
                    controllingWidget: item.id,
                    filterSetName: item.filterSetName,
                    selectedBarNames: selectedBarNames.filter(
                        b => b !== e.name
                    ),
                    observationIds: Array.from(selectedObservationIds).filter(
                        s => !Array.from(e.observationIds).includes(s)
                    ),
                })
            );
        }
    }
};

export const getAxisRange = (
    observationValues,
    fieldName,
    userInputMin,
    userInputMax,
    allowDecimals
) => {
    // This determines the graph axis range to use based on the extremes of the
    // data and/or user input from the configuration sections

    // if not otherwise specified by the user, default to an axis minimum value
    // that is the minimum value in the filterset and an axis maximum value that is
    // the maximum value in the filterset

    // if specified by the user, use exactly what they put even if it clips the
    // data thereby hiding some.

    // Zero is a valid numeric value so when filtering out other falsey values
    // like null or undefined we need to explicitly exclude zero
    const fieldValues = map(observationValues, fieldName).filter(
        v => v === 0 || !!v
    );

    if (fieldValues.length === 0) {
        return [null, null];
    }

    const rawMinValue = min(fieldValues);
    const minValue =
        rawMinValue && allowDecimals ? rawMinValue : Math.floor(rawMinValue);

    const rawMaxValue = max(fieldValues);
    const maxValue =
        rawMaxValue && allowDecimals ? rawMaxValue : Math.ceil(rawMaxValue);

    const axisMin = !isNil(userInputMin) ? userInputMin : minValue;
    const axisMax = !isNil(userInputMax) ? userInputMax : maxValue;

    return [axisMin, axisMax];
};

export const getYAxisWidth = (data, dataKey, formatter = value => value) => {
    // Get the y axis width based on the longest word in any of the
    // y-axis tick labels.
    const longestYTickLabel = Math.max(
        ...data.map(tick =>
            Math.max(
                ...String(formatter(tick[dataKey]))
                    .split(' ')
                    .map(bit => bit.length)
            )
        )
    );
    return Math.max(longestYTickLabel * 10, 40);
};

export const getXAxisHeight = (data, dataKey) => {
    // Get the x axis height based on the longest x-axis tick labels.
    const longestXTickLabel = Math.max(
        ...data.map(tick => String(tick[dataKey]).length)
    );
    return Math.max(longestXTickLabel * 6 + 20, 40);
};

export const isYearFieldName = fieldName =>
    fieldName === 'YearOfObservation' || fieldName.endsWith('__year');

export const isDayFieldName = fieldName =>
    fieldName === 'DayOfObservation' || fieldName.endsWith('__day');

export const isYearDayFieldName = fieldName =>
    fieldName === 'YearDayOfObservation' || fieldName.endsWith('__yearday');

export const isTimeFieldName = fieldName =>
    fieldName === 'TimeOfObservation' || fieldName.endsWith('__time');

export const isMonthFieldName = fieldName =>
    fieldName === 'MonthOfObservation' || fieldName.endsWith('__month');

export const fieldCanBeRescaled = fieldDefinition =>
    // Year and StationName are special cases. Year is a numeric field, but we
    // can't enumerate all possible values into the future. StationName can be
    // on the XAxis but is non-numeric.
    !fieldDefinition?.values &&
    fieldDefinition?.name &&
    !isYearFieldName(fieldDefinition?.name) &&
    fieldDefinition?.name !== 'stationName';

export const getAxisFormatter = (axisType, defaultFormatter = identity) =>
    isDayFieldName(axisType)
        ? dayOfYearString
        : isYearDayFieldName(axisType)
        ? yearDayString
        : isTimeFieldName(axisType)
        ? timeOfDayString
        : isYearFieldName(axisType)
        ? v => String(v)
        : defaultFormatter;

export const roundValue = (precision, value) =>
    isNil(value)
        ? value
        : isNil(precision)
        ? Number(value)
        : Number.parseFloat(Number(value).toFixed(precision));

export const isNilOrNan = value => isNil(value) || Number.isNaN(value);

// precision should be increased for some aggregation methods
export const getAggregatedPrecision = (precision, method) => {
    if (isNil(precision)) {
        return method === 'count' ? 0 : precision;
    }
    switch (method) {
        case 'count':
            return 0;
        case 'average':
            return precision + 2;
        case 'mean':
            return precision + 2;
        // Median needs a little extra precision for handling cases like
        // [1, 2, 3, 4] which ought to result in 2.5
        // When the last digit is a 0 though, you should trim it off.
        case 'median':
            return precision + 1;
        case 'quantile':
            return precision + 1;
        default:
            return precision;
    }
};

// get a formatter to go from a number to a string.
export const getAggregatedFormatter = (field, method, includeUnits = false) =>
    method === 'count'
        ? v => v.toFixed()
        : getAxisFormatter(field.name || '', v => {
              if (isNil(v)) {
                  return unknownValue;
              }
              const precision = getAggregatedPrecision(field.precision, method);
              const valueString = v.toLocaleString(undefined, {
                  maximumFractionDigits: precision,
              });
              if (includeUnits && field.units) {
                  return `${valueString} ${formatUnit(field.units)}`;
              }
              return valueString;
          });

export const tickFormatter = (value, min, max) => {
    const range = max - min;
    // If the axis range is greater than 0.05 then there will be no
    // duplicate labels with a decimal precision of two
    if (range > 0.05) {
        return round(value, 2);
    }

    const rangeStringSplit = range.toString().split('.');
    const rangeString = rangeStringSplit[rangeStringSplit.length - 1];
    let nonZeroIndex = 0;
    for (let i = 0; i < rangeString.length; i++) {
        if (rangeString[i] !== '0') {
            nonZeroIndex = i;
            break;
        }
    }

    const digit = Number(rangeString[nonZeroIndex]);
    const decimalPlaces = digit <= 5 ? nonZeroIndex + 2 : nonZeroIndex + 1;

    return round(value, decimalPlaces);
};

export const labelForField = f =>
    f?.units ? `${f.label} (${formatUnit(f.units)})` : f?.label;

// Returns a shortened version of the text if it is longer than the
// specified maximum length by truncating it in the middle and replacing
// the truncated text with "..."
// The three-character length of "..." is included in the total length of the
// returned string (e.g. shortenText('abcdefgh', 5) returns 'a...h')
export const shortenText = (text, maxLength) => {
    if (isNil(text)) {
        return text;
    }
    if (maxLength <= 0) {
        return '';
    }
    if (!Number.isFinite(maxLength)) {
        return '';
    }
    text = text.toString();
    if (text.length <= maxLength) {
        return text;
    }
    const replacement = '...';
    if (maxLength === 1) {
        return text.substr(0, 1) + replacement;
    }
    const truncateLength = Math.max(0, maxLength - replacement.length);
    const frontChars = Math.max(1, Math.ceil(truncateLength / 2));
    const backChars = Math.max(1, Math.floor(truncateLength / 2));
    return (
        text.substr(0, frontChars) +
        replacement +
        text.substr(text.length - backChars)
    );
};

// Pad a single-item time series with null data before and after.
// This function mutates the `data` argument
export const maybePadUplotData = (data, days = 1) => {
    if (data && data[0]?.length === 1) {
        const unixDate = data[0][0];
        [...Array(days).keys()] // Create an array of day numbers
            .map(x => x + 1) // Increment so the array of days is 1-based
            .forEach(x => {
                data[0]?.unshift(
                    moment
                        .unix(unixDate)
                        .add(-1 * x, 'days')
                        .unix()
                );
                data[1]?.unshift(null);
                data[2]?.unshift(null);

                data[0]?.push(
                    moment
                        .unix(unixDate)
                        .add(x, 'days')
                        .unix()
                );
                data[1]?.push(null);
                data[2]?.push(null);
            });
    }
    return data;
};

// If the range can't be divided into equal partitions return a new, increased
// max value that will allow for equal partitioning, otherwise return max
export const getEquallyDivisibleIntegearRangeSize = (
    rangeSize,
    partitionCount
) => {
    if (!partitionCount || partitionCount <= 0) {
        throw new Error('Number of partitions must be greater than 0');
    }

    const partitionSize = Math.ceil(rangeSize / partitionCount);

    // Calculate a potentially new rangeSize value so that all partitions
    // would have the same number of values
    return partitionSize * partitionCount;
};
